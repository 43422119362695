import { defineAction } from '_utils/redux'
import * as companyServices from '_services/company'

export const GET_COMPANY = defineAction('GET_COMPANY')
export const GET_CONTRACT_OPTIONS = defineAction('GET_CONTRACT_OPTIONS')
export const UPDATE_COMPANY = defineAction('UPDATE_COMPANY')
export const GET_COMPANIES = defineAction('GET_COMPANIES')
export const GET_COMPANIES_SIMPLE = defineAction('GET_COMPANIES_SIMPLE')
export const GET_COMPANY_BY_ID = defineAction('GET_COMPANY_BY_ID')
export const CREATE_CONTRACT_SIGNERS = defineAction('CREATE_CONTRACT_SIGNERS')
export const GET_CONTRACT_SIGNERS = defineAction('GET_CONTRACT_SIGNERS')
export const POST_GENERATE_CONTRACT = defineAction('POST_GENERATE_CONTRACT')
export const POST_REQUEST_SIGNATURE_EMAIL = defineAction('POST_REQUEST_SIGNATURE_EMAIL')

export const getCompany = () => (dispatch, getState) => {
  const data = companyServices.getCompany(getState().user.authToken)
  dispatch({
    type: GET_COMPANY.ACTION,
    payload: data,
  })
  return data
}

export const getContractOptions = () => (dispatch, getState) => {
  const data = companyServices.getContractOptions(getState().user.authToken)
  dispatch({
    type: GET_CONTRACT_OPTIONS.ACTION,
    payload: data,
  })
  return data
}

export const updateCompany =
  (companyId, payload, transformOnlyResponse = false) =>
  (dispatch, getState) => {
    const data = companyServices.updateCompany(
      getState().user.authToken,
      companyId,
      payload,
      transformOnlyResponse
    )
    dispatch({
      type: UPDATE_COMPANY.ACTION,
      payload: data,
    })
    return data
  }

export const getCompanyById = companyId => (dispatch, getState) =>
  dispatch({
    type: GET_COMPANY_BY_ID.ACTION,
    payload: companyServices.getCompanyById(getState().user.authToken, companyId),
  })

export const getCompanies = () => (dispatch, getState) => {
  const data = companyServices.getCompanies(getState().user.authToken)
  dispatch({
    type: GET_COMPANIES.ACTION,
    payload: companyServices.getCompanies(getState().user.authToken),
  })
  return data
}

export const getCompaniesSimple = () => async (dispatch, getState) => {
  const data = await companyServices.getCompaniesSimple(getState().user.authToken)
  dispatch({
    type: GET_COMPANIES_SIMPLE.ACTION,
    payload: async () => data,
  })
}

export const postContractSigners = payload => (dispatch, getState) => {
  const data = companyServices.postContractSigners(getState().user.authToken, payload)
  dispatch({
    type: CREATE_CONTRACT_SIGNERS.ACTION,
    payload: data,
  })
  return data
}

export const getContractSigners = () => (dispatch, getState) => {
  const data = companyServices.getContractSigners(getState().user.authToken)
  dispatch({
    type: GET_CONTRACT_SIGNERS.ACTION,
    payload: data,
  })

  return data
}

export const postGenerateContract = () => async (dispatch, getState) => {
  const data = await companyServices.postGenerateContract(getState().user.authToken)
  dispatch({
    type: POST_GENERATE_CONTRACT.ACTION,
    payload: async () => data,
  })

  return data
}

export const requestSignatureEmail = () => (dispatch, getState) => {
  const data = companyServices.postRequestSignatureEmail(getState().user.authToken)
  dispatch({
    type: POST_REQUEST_SIGNATURE_EMAIL.ACTION,
    payload: data,
  })
}
