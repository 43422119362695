import { List, Map, Record } from 'immutable'
import { normalize } from 'normalizr'

import { createReducer } from '_utils/redux'
import { Budget } from '_models/'
import { budgetSchema } from '_models/budget'
import {
  GET_SERVICE_ORDER,
  GET_SERVICE_ORDER_APPROVAL_AND_EXECUTION_INFOS,
} from '_modules/service-orders/actions'

import {
  GET_BUDGET,
  GET_BUDGET_BY_ID,
  UPDATE_BUDGET,
  UPDATE_BUDGET_ITEMS,
  DELETE_BUDGET_ITEM,
  UPDATE_BUDGET_ITEM,
  DELETE_BUDGET_PICTURE,
  ADD_BUDGET_PICTURE,
  REFUSE_BUDGET,
  UNREFUSE_BUDGET,
  SENT_BUDGET_COUNTER_PROPOSAL,
  GET_BUDGET_BY_SERVICE_ORDER,
  GET_PAYER_OPTIONS,
  GET_STEP_STATUS_LOG,
  SEND_BUDGET_EMAIL,
  CREATE_OPEN_PROPOSE_BUDGET,
  GET_REASONS_TO_REFUSAL,
  CREATE_COVERAGE_RULE,
  GET_COVERAGE_RULE_VERSION,
} from './actions'

const INITIAL_STATE = new Record({
  results: Map(),
  providerBudget: {},
  tradesmanBudget: undefined,
  payerOptions: undefined,
  stepStatusLog: List(),
  currentInnerBudget: undefined,
  reasonsToRefuse: List(),
  coverageRule: undefined,
  coverageRuleVersion: undefined,
})()

const setBudgetById = (state, { payload }) => {
  return state.setIn(['results', `${payload.id}`], new Budget(payload))
}

const updateBudgetById = (state, { payload }) => {
  return state.mergeIn(['results', `${payload.id}`], Map(payload))
}

export default createReducer(INITIAL_STATE, {
  [GET_BUDGET.FULFILLED]: setBudgetById,

  [GET_BUDGET_BY_ID.FULFILLED]: setBudgetById,

  [GET_SERVICE_ORDER_APPROVAL_AND_EXECUTION_INFOS.FULFILLED]: (state, { payload }) => {
    const approvedBudget = state?.get('results').find(budget => budget.get('approved') === true)

    const newPayload = {
      ...payload,
      id: String(approvedBudget?.get('id')),
    }

    return updateBudgetById(state, {
      payload: newPayload,
    })
  },

  [UPDATE_BUDGET.FULFILLED]: updateBudgetById,

  [UPDATE_BUDGET_ITEMS.FULFILLED]: (state, { payload }) => {
    return state.setIn(['results', `${payload.id}`, 'budgetItems'], new List(payload.budget_items))
  },

  [DELETE_BUDGET_ITEM.FULFILLED]: (state, { meta }) => {
    const budgetItems = state.getIn(['results', meta.budgetId, 'budgetItems'])

    return state.setIn(
      ['results', meta.budgetId, 'budgetItems'],
      budgetItems.filter(item => item.get('id') !== meta.itemId)
    )
  },

  [UPDATE_BUDGET_ITEM.FULFILLED]: (state, { payload, meta }) => {
    const budgetItems = state.getIn(['results', meta.budgetId, 'budgetItems'])

    const itemToEditIndex = budgetItems.findIndex(item => item.get('id') === payload.id)

    let updatedBudgetItems = budgetItems
    if (itemToEditIndex > -1) {
      updatedBudgetItems = budgetItems.set(itemToEditIndex, Map(payload))
    }

    return state.setIn(['results', `${payload.budget}`, 'budgetItems'], updatedBudgetItems)
  },

  [DELETE_BUDGET_PICTURE.FULFILLED]: (state, { meta }) => {
    const budgetPictures = state.getIn(['results', meta.budgetId, 'budgetPictures'])
    const updatedBudgetPictures = budgetPictures.filter(item => item.get('id') !== meta.itemId)

    return state.setIn(['results', meta.budgetId, 'budgetPictures'], List(updatedBudgetPictures))
  },

  [ADD_BUDGET_PICTURE.FULFILLED]: (state, { payload, meta }) => {
    let budgetPictures = state.getIn(['results', meta.budgetId, 'budgetPictures'])

    if (!List.isList(budgetPictures)) {
      budgetPictures = List()
    }

    return state.setIn(
      ['results', meta.budgetId, 'budgetPictures'],
      List([payload, ...budgetPictures])
    )
  },

  [REFUSE_BUDGET.FULFILLED]: (state, { payload }) => {
    return state.mergeIn(['results', `${payload.id}`], Map(payload))
  },

  [UNREFUSE_BUDGET.FULFILLED]: (state, { payload }) => {
    const newPayload = {
      ...payload,
      tradesman: state.get('results', `${[payload.id]}`, 'tradesman'),
    }
    return state.mergeIn(['results', `${payload.id}`], Map(newPayload))
  },

  [GET_SERVICE_ORDER.FULFILLED]: (state, { payload }) => {
    const normalizedBudgets = normalize(payload.budget, budgetSchema)?.entities?.results

    return state.mergeIn(['results'], Map(normalizedBudgets))
  },

  [SENT_BUDGET_COUNTER_PROPOSAL.FULFILLED]: (state, { payload }) => {
    return state.mergeIn(['results', `${payload.id}`], Map(payload))
  },

  [GET_BUDGET_BY_SERVICE_ORDER.FULFILLED]: (state, { payload }) => {
    return state.set('tradesmanBudget', payload)
  },

  [GET_PAYER_OPTIONS.FULFILLED]: (state, { payload }) => {
    return state.set('payerOptions', payload)
  },

  [GET_STEP_STATUS_LOG.FULFILLED]: (state, { payload }) => {
    return state.set('stepStatusLog', List(payload))
  },

  [SEND_BUDGET_EMAIL.FULFILLED]: (state, { payload }) => {
    const { stepLogServiceOrder: lastStepLog } = payload

    const stepStatusLog = state.get('stepStatusLog').toJS()
    const formattedPayload = [lastStepLog, ...stepStatusLog]

    return state.update('stepStatusLog', () => List(formattedPayload))
  },

  [CREATE_OPEN_PROPOSE_BUDGET.FULFILLED]: (state, { payload }) => {
    return state.set('currentInnerBudget', payload)
  },

  [GET_REASONS_TO_REFUSAL.FULFILLED]: (state, { payload }) =>
    state.set('reasonsToRefuse', List(payload)),

  [CREATE_COVERAGE_RULE.FULFILLED]: (state, { payload }) => {
    return state.set('coverageRule', payload)
  },
  [GET_COVERAGE_RULE_VERSION.FULFILLED]: (state, { payload }) => {
    return state.set('coverageRuleVersion', payload)
  },
})
